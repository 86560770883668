import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Box } from '@mui/material';
import { ASSET_TYPE, ReportFE } from '../../interfaces/uiv2';
import { getAssetIcon } from '../../utils/getAssetIcon';
import { TableCellRenderer } from '../../interfaces/general';
import { DATE_SETTINGS, NO_VALUE } from '../../utils/config';
import { DEFAULT_EMPTY_CELL_VALUE } from '../../utils/table';
import { DefaultSearchableCell } from '../../components/BaseTable';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { DownloadButton, DownloadExtension } from '../../components/DownloadButton';
import { convertMeasureUnitToUnitId, getFormattedCapacityValue } from '../../utils/helpers';

export function getClass(number: number, type: ASSET_TYPE, solarThresholds = [6, 12], windThresholds = [12, 20]) {
    if (!number) {
        return '';
    }
    if (type === ASSET_TYPE.SOLAR) {
        if (number <= solarThresholds[0]) {
            return 'text-green';
        } else if (number <= solarThresholds[1]) {
            return 'text-warning';
        } else {
            return 'text-error';
        }
    } else if (type === ASSET_TYPE.WIND) {
        if (number <= windThresholds[0]) {
            return 'text-green';
        } else if (number <= windThresholds[1]) {
            return 'text-warning';
        } else {
            return 'text-error';
        }
    } else {
        throw new Error("Invalid type provided. Only 'solar' and 'wind' are supported.");
    }
}

export const getTableHeadCells = (tList: TFunction): TableCellRenderer<ReportFE>[] => {
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: tList('tableHead.no'),
            value: (row) => {
                return (
                    <PageHeader
                        id={row.id}
                        variant="small4"
                        subtitle={row.index < 10 ? `0${row.index}` : `${row.index}`}
                    />
                );
            },
            sort: false
        },
        {
            id: 'type',
            maxWidth: 75,
            label: tList('tableHead.type'),
            value: (row) => {
                return getAssetIcon(row.asset.type);
            },
            sort: true,
            sort_id: 'asset.type'
        },
        {
            id: 'name',
            label: tList('tableHead.report'),
            sort: true,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.name;
                const handler = handlers?.['name'];
                const callback = handler ? handler(row) : undefined;

                return <DefaultSearchableCell value={value} searchValue={searchValue} isLink linkUrl={callback()} />;
            },
            sort_id: 'name'
        },
        {
            id: 'asset-name',
            label: tList('tableHead.asset'),
            sort: false,
            value: (row, searchValue, onClick, handlers) => {
                const value = row.asset.name;
                const handler = handlers?.['asset'];
                const callback = handler ? handler(row) : undefined;

                return <DefaultSearchableCell value={value} searchValue={searchValue} isLink linkUrl={callback()} />;
            }
        },
        {
            id: 'from',
            label: tList('tableHead.from'),
            sort: false,
            value: (row, searchValue) => {
                const rawValue = row.from_date;
                const timezone = dayjs.tz.guess();
                const utcValue = dayjs.utc(rawValue);
                const localTime = dayjs(utcValue).tz(timezone).format(DATE_SETTINGS.shortV2FormatHoursIncluded);
                const date = rawValue && dayjs(rawValue).isValid() ? localTime : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'to',
            label: tList('tableHead.to'),
            sort: false,
            value: (row, searchValue) => {
                const rawValue = row.to_date;
                const timezone = dayjs.tz.guess();
                const utcValue = dayjs.utc(rawValue);
                const localTime = dayjs(utcValue).tz(timezone).format(DATE_SETTINGS.shortV2FormatHoursIncluded);
                const date = rawValue && dayjs(rawValue).isValid() ? localTime : DEFAULT_EMPTY_CELL_VALUE;

                return <DefaultSearchableCell value={date} searchValue={searchValue} />;
            }
        },
        {
            id: 'accuracy',
            label: tList('tableHead.NMAE'),
            tooltip: tList('NMAETooltip'),
            smallTooltip: true,
            sort: false,
            justifyContent: 'center',
            value: (row, searchValue) => {
                const value = (1 - +(row?.accuracy || 0)) * 100;
                return row?.accuracy ? (
                    <DefaultSearchableCell
                        value={`${value.toFixed(2)}%`}
                        searchValue={searchValue}
                        className={getClass(value, row.asset.type)}
                    />
                ) : (
                    NO_VALUE
                );
            }
        },
        {
            id: 'mape',
            label: tList('tableHead.MAPE'),
            tooltip: tList('MAPETooltip'),
            smallTooltip: true,
            sort: false,
            justifyContent: 'center',
            value: (row, searchValue) => {
                const value = +(row?.errors?.['MAPE'] || 0);
                return row?.errors?.['MAPE'] ? (
                    <DefaultSearchableCell
                        value={`${value.toFixed(2)}%`}
                        searchValue={searchValue}
                        className={getClass(value, row.asset.type, [20, 40], [25, 45])}
                    />
                ) : (
                    NO_VALUE
                );
            }
        },
        {
            id: 'MAE',
            label: tList('tableHead.MAE'),
            sort: false,
            justifyContent: 'center',
            value: (row, searchValue) => {
                const value = +(row?.errors?.['MAE'] || 0);
                const val = row?.errors?.['MAE']
                    ? getFormattedCapacityValue(value, convertMeasureUnitToUnitId(row.asset.measure_unit), true, 3)
                    : NO_VALUE;

                return <DefaultSearchableCell value={val} searchValue={searchValue} />;
            }
        },
        {
            id: 'download',
            label: tList('tableHead.download'),
            sort: false,
            justifyContent: 'center',
            value: (row) => {
                const filename = `Ogre_Report_${row.name}`;
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <DownloadButton type={DownloadExtension.Pdf} id={row.id} value="reports" filename={filename} />
                        <DownloadButton
                            type={DownloadExtension.Excel}
                            id={row.id}
                            value="reports"
                            filename={filename}
                        />
                        <DownloadButton type={DownloadExtension.Csv} id={row.id} value="reports" filename={filename} />
                    </Box>
                );
            }
        }
    ];
};
